<template>
  <div class="landing">
    <IconMusicSolid size="150" color="#6461F644"/>
    <span class="landing__title" v-text="title"/>
    <div v-if="lastStockName" class="landing__link" @click="$emit('redirect')">
      <span v-text="description"/>
      <div v-text="`«${lastStockName}»`"/> 
    </div>
  </div>  
</template>


<script>
import IconMusicSolid from '@/components/icons/IconMusicSolid';

export default {
  name: 'StockRedirectLanding',

  components: {
    IconMusicSolid,
  },

  props: {
    lastStockName: [String, Boolean],
    description: {
      type: String,
      default: 'Смотреть результаты предыдущего:',
    },
    title: {
      type: String,
      default: 'Следующий розыгрыш состоится в ближайшее время. Следите за объявлениями в игре!',
    },
  },
};
</script>


<style lang="scss" scoped>
.landing {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 30px;
  line-height: 22px;
  gap: 20px;
  &__title {
    font-weight: 700;
    font-size: 18px;
  }
  &__link {
    font-weight: 600;
    font-size: 14px;
    color: #6461F6;
    cursor: pointer;
  }
}
</style>