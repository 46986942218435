<template>
  <TopMain />
</template>

<script>
import axios from "axios";
import TopMain from "@/components/game/top/TopMain";

export default {
  name: "Top",
  components: {
    TopMain,
  },
};
</script>

<style>
</style>