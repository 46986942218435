<template>
  <div class="wrapper">
    <div class="page page-top">

      <div class="page__title">
        <h1>Рейтинг игроков</h1>
      </div>

      <template v-if="selectedMenuItem">
        <TopMenu
          :prevValue="ratingLength > 1 ? prevTextValue : null"
          :currValue="currTextValue"
          :nextValue="ratingLength > 1 ? nextTextValue : null"
          @prev="lodash.invoke(swiperRef, 'slidePrev')"
          @next="lodash.invoke(swiperRef, 'slideNext')"
        />

        <Swiper 
          ref="swiper"
          class="swiper_animation"
          @activeIndexChange="activeIndexChange" 
          :loop="ratingLength > 1"
        >
          <SwiperSlide
            v-for="([ratingId, ratingOptions]) of lodash.entries(filtredRatings)"
            :key="ratingId"
          >
            <StockRedirectLanding 
              v-if="ratingId === 'stock' && isNotFoundStock"
              :lastStockName="lodash.get(filtredRatings, 'lastStock.show') && lastEndedStock.name"
              @redirect="lodash.invoke(swiperRef, 'slideNext')"
            />
            <StockRedirectLanding 
              v-else-if="ratingId === 'lastStock' && isNotFoundLastStock"
              :lastStockName="lodash.get(filtredRatings, 'stock.show') && selectedStock.name"
              title="Во время загрузки произошла ошибка"
              description="Смотреть рейтинг текущего розыгрыша:"
              @redirect="lodash.invoke(swiperRef, 'slidePrev')"
            />
            <TopTable
              v-else
              :currentUserId="user._id"
              :categories="ratingBy[ratingId].categories"
              :loading="!ratingBy[ratingId].loaded"
            />
          </SwiperSlide>
        </Swiper>

      </template>
    </div>
<!--    <div class="landscape" v-if="orientation === 'landscape' && gamers && user">
      <div class="me_info">
        <div class="left">
          <div class="my my_position" @click="myPosition">
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.5354 5.37409C16.3035 5.06486 17.067 5.82837 16.7578 6.59646L11.1935 20.4175C10.8524 21.2648 9.62501 21.1762 9.40912 20.2887L8.064 14.7587C7.98098 14.4174 7.71449 14.1509 7.37318 14.0679L1.84322 12.7228C0.955668 12.5069 0.867064 11.2795 1.71441 10.9384L15.5354 5.37409Z"
                fill="white" />
            </svg>
          </div>
          <span>Место</span>
          <div class="my my_place">{{ user.place || 0 }}</div>
        </div>
        <div class="middle">
          <div>Вы</div>
        </div>
        <div class="right">
          <span>Баллы</span>
          <div class="my my_score">{{ user.score || 0 }}</div>
        </div>
      </div>
      <div class="gamers">
        <div class="gamer" v-for="gamer of gamers" :key="gamer._id" ref="gamers">
          <div class="place" :class="{ 'btn-gradient': gamer.place === 1 || gamer.place === 2 || gamer.place === 3 }">
            {{ gamer.place }}
          </div>
          <div class="info">
            <div class="name" v-if="gamer._id === user._id">Вы</div>
            <div class="name" v-else>{{ gamer.login }}</div>
            <div class="score">{{ gamer.score }}</div>
          </div>
        </div>
      </div>
    </div>-->
  </div>
</template>


<script>
import _ from 'lodash';
import axios from 'axios';

import { Swiper, SwiperSlide } from 'swiper-vue2';
import StockRedirectLanding from './StockRedirectLanding';
import TopTable from './TopTable';
import TopMenu from './TopMenu';

import { mapGetters } from 'vuex';

export default {
  name: 'TopMain',

  components: {
    Swiper,
    SwiperSlide,
    StockRedirectLanding,
    TopTable,
    TopMenu,
  },

  data: () => ({
    lodash: _,
    selectedMenuItem: null,
    gamers: null,
    slideIndex: null,
    swiperRef: null,
    ratingBy: {
      all: {
        loaded: false,
        categories: [],
      },
      stock: {
        loaded: false,
        categories: [],
      },
      lastStock: {
        loaded: false,
        categories: [],
      },
      day: {
        loaded: false,
        categories: [],
      },
    },
    prevTextValue: null,
    currTextValue: null,
    nextTextValue: null,
  }),

  computed: {
    ...mapGetters([
      'ratings',
      'lastEndedStock',
      'selectedStock',
      'usersList',
      'usersListWithUser',
      'prizesList',
      'axiosOptions',
      'orientation',
      'token',
      'user',
    ]),

    filtredRatings: ths => _.pickBy(ths.ratings, r => ths.isValidUserCategory(r.userCategory)),
    ratingKeys: ths => Object.keys(ths.filtredRatings),
    ratingLength: ths => _.size(ths.ratingKeys),

    isNotFoundStock() {
      return this.ratingBy.stock.loaded 
        && !_.get(this.ratingBy.stock.categories[0], 'users.length');
    },
    isNotFoundLastStock() {
      return this.ratingBy.lastStock.loaded 
        && !_.get(this.ratingBy.lastStock.categories[0], 'users.length');
    },
  },

  watch: {
    'user._id': 'registerRatingDay',
    'lastEndedStock._id': 'registerRatingsStock',
    'selectedStock._id': 'registerRatingsStock',
    usersList: {
      deep: true,
      handler: 'registerRatingsStock',
    },
    ratings: {
      deep: true,
      immediate: true,
      handler(ratings) {
        this.slideIndex = 0;
        this.selectedMenuItem = _.defaultsDeep({},
          ratings.all,
          ratings.day,
          ratings.stock,
          ratings.lastStock,
        ).id;
      },
    },
    slideIndex: {
      immediate: true,
      handler(currIndex) {
        if (currIndex != currIndex.toString()) return;
        const nextIndex = (currIndex + 1) % this.ratingLength;
        const prevIndex = (currIndex - 1 + this.ratingLength) % this.ratingLength;
        this.prevTextValue = _.get(this.ratings, `${this.ratingKeys[prevIndex]}.name`);
        this.currTextValue = _.get(this.ratings, `${this.ratingKeys[currIndex]}.name`);
        this.nextTextValue = _.get(this.ratings, `${this.ratingKeys[nextIndex]}.name`);
        const wrapper = _.invoke(this.$refs, 'swiper.$el.querySelector', '.swiper-wrapper');
        wrapper && [...wrapper.children].forEach(slide => slide.scrollTo(pageXOffset, 0));
      },
    }
  },

  async mounted() {
    this.registerRatings();
    this.$store.dispatch('checkGameDuration');
    this.swiperRef = this.$refs.swiper?.swiperRef;
    if (this.user && this.user.login === 'guest') {
      this.$router.push('/')
    };
  },

  methods: {
    isValidUserCategory(categories = []) {
      return categories.includes(this.user.user_category);
    },

    activeIndexChange({ realIndex }) {
      this.slideIndex = (realIndex - 1 + this.ratingLength) % this.ratingLength;
    },

    registerRatings() {
      this.registerRatingAll();
      this.registerRatingDay();
      this.registerRatingsStock();
      setTimeout(() => this.ratingBy.stock.loaded = true, 2000);
      setTimeout(() => this.ratingBy.lastStock.loaded = true, 2000);
    },

    registerRatingsStock() {
      this.registerRatingStock();
      this.registerRatingLastStock();
    },

    async registerRatingAll() {
      try {
        if (!_.get(this.filtredRatings, 'all.show')) return;
        if (this.ratingBy.all.categories.length) return;
        const params = { userId: this.user._id };
        const options = { ...this.axiosOptions, params };
        const response = await axios.get(`/api/rating/all/top`, options);
        this.ratingBy.all.loaded = true;
        this.$set(this.ratingBy.all, 'categories', [{
          users: response.data,
        }]);
      } catch(err) {
        console.error(err);
      };
    },

    async registerRatingDay() {
      try {
        if (!_.get(this.filtredRatings, 'day.show')) return;
        if (this.ratingBy.day.categories.length) return;
        if (!this.user) return;
        const params = {};
        params.userId = this.user._id;
        const options = { ...this.axiosOptions, params };
        const response = await axios.get('/api/rating/day/top', options);
        this.ratingBy.day.loaded = true;
        this.$set(this.ratingBy.day, 'categories', [{
          users: response.data,
          title: 'Сегодня',
        }]);
      } catch(err) {
        console.error(err);
      };
    },

    async registerRatingStock() {
      try {
        if (!_.get(this.filtredRatings, 'stock.show')) return;
        if (this.ratingBy.stock.categories.length) return;
        if (!this.user || !this.selectedStock._id) return;
        const params = {};
        params.userId = this.user._id;
        params.stockId = this.selectedStock._id;
        const options = { ...this.axiosOptions, params };
        const response = await axios.get('/api/rating/stock/top', options);
        this.ratingBy.stock.loaded = true;
        this.$set(this.ratingBy.stock, 'categories', [{
          title: this.selectedStock.name,
          users: response.data,
        }]);
      } catch(err) {
        console.error(err);
      };
    },

    async registerRatingLastStock() {
      try {
        if (!_.get(this.filtredRatings, 'lastStock.show')) return;
        if (this.ratingBy.lastStock.categories.length) return;
        if (!this.user || !this.lastEndedStock._id) return;
        const params = {};
        params.userId = this.user._id;
        params.stockId = this.lastEndedStock._id;
        const options = { ...this.axiosOptions, params };
        const response = await axios.get('/api/rating/stock/top', options);

        const groupByPrizes = _.groupBy(response.data, 'prize');
        const prizesList = _.keys(this.lastEndedStock.prizes);
        const categories = _.reduce(groupByPrizes, (acc, users, prizeId) => {
          let priorityIndex = _.indexOf(prizesList, prizeId);
          if (priorityIndex === -1) priorityIndex = prizesList.length;
          const title = _.get(this.prizesList[prizeId], 'name', 'Без приза');
          _.set(acc, priorityIndex, { title, users });
          return acc;
        }, _.times(prizesList.length + 1));

        this.ratingBy.lastStock.loaded = true;
        this.$set(this.ratingBy.lastStock, 'categories', _.filter(categories, 'users.length'));
      } catch(err) {
        console.error(err);
      };
    },
  },
};
</script>


<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: 100%;
  color: #fff;
  font-family: "Poppins", sans-serif;
  max-width: 100vw;
  overflow-x: hidden;
  max-width: inherit;
  .page {
    color: #14142B;
    max-height: 100vh;
    height: 100%;
    display: grid;
    grid-template-rows: 125px 40px 1fr;
    max-width: inherit;
    .page__title {
      width: 100vw;
      max-width: inherit;
      padding-top: 65px;
      font-weight: 700;
      font-size: 28px;
      line-height: 60px;
      text-align: center;
    }
    .swiper_animation {
      animation-name: open_swiper;
      animation-duration: .5s;
      animation-delay: 1s;
    }
  }
}

@keyframes open_swiper {
  50% {
    transform: translateX(-10px);
  }
}
</style>