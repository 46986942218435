<template>
  <div class="users">
    <ThePreloader v-if="loading" absolute/>
    <div v-else class="users__wrapper">
      <div 
        v-for="(category, categoryIndex) of categories" 
        :key="`${category.title}_${categoryIndex}`" 
        class="category" 
      >
        <div
          v-if="category.title"
          class="category_title"
          v-text="category.title"
        />
        <div
          v-for="user of category.users" 
          :key="user._id" 
          class="user" 
        >
          <div 
            class="user__place"
            :class="{ 
              'user__place-top': [1, 2, 3].includes(user.place), 
              'user__place-my': user._id == currentUserId
            }"
            v-text="user.place || '#'"
          />

          <div class="user__info">
            <div class="user__info_login" v-text="user._id == currentUserId ? 'Вы' : user.login || user.phone || 'Неизвестно'"/>
            <div class="user__info_score" v-text="user.score || 0"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script type="text/javascript">
import ThePreloader from '../Preloader';

export default {
  name: 'TopTable',

  components: {
    ThePreloader,
  },

  props: {
    currentUserId: {
      type: String,
    },
    categories: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
    },
  },
};
</script>


<style lang="scss" scoped>
.users {
  padding: 0px 20px 20px 20px;
  font-size: 15px;
  font-weight: 700;
  position: relative;
  min-height: 300px;

  &__wrapper {
    display: grid;
    gap: 20px;
  }

  .category {
    display: grid;
    gap: 10px;
    background: #eee8;
    border-radius: 10px;
    padding: 15px;
    &_title {
      font-size: 16px;
      text-align: center;
      background: #0001;
      padding: 5px 0;
      border-radius: 5px;
    }
    .user {
      width: 100%;
      display: grid;
      grid-template-columns: 48px 1fr;
      height: 48px;
      gap: 30px;
      &__place {
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba($color: #B5C7E1, $alpha: .2) !important;
        &-my {
          color: #4700AB;
          border: 3px solid #4E4B66;
        }
        &-my, &-top {
          background-color: rgba($color: #610BEF, $alpha: .2) !important;
        }
      }

      &__info {
        flex-grow: 1;
        display: flex;
        align-items: center;
        background: rgba(255, 255, 255, 0.2);
        justify-content: space-between;
        &_login {
          font-weight: 500;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &_score {
          font-size: 14px;
        }
      }
    }
  }
}
</style>