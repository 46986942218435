<template>
  <ul class="menu">
    <li v-text="prevValue" @click="$emit('prev')"/>
    <li v-text="currValue"/>
    <li v-text="nextValue" @click="$emit('next')"/>
  </ul>
</template>


<script>
export default {
  name: 'TopMenu',

  props: {
    prevValue: String,
    currValue: String,
    nextValue: String,
  },
};
</script>


<style lang="scss" scoped>
.menu {
  font-size: 14px;
  padding: 0 30px 0 35px;
  font-weight: 600;
  color: #D8D9E0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 100vw;
  li {
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 5px;
    &:nth-child(1) {
      text-align: left;
      justify-content: flex-start;
    }
    &:nth-child(2) {
      color: #333;
      text-align: center;
      justify-content: center;
      &::after {
        content: "";
        width: 50%;
        background-color: #333;
        height: 3px;
        border-radius: 3px;
        position: absolute;
        bottom: 0;
        left: 25%;
      }
    }
    &:nth-child(3) {
      text-align: right;
      justify-content: flex-end;
    }
  }
}
</style>